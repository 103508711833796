<template>
  <lazy-component class="inspirations">
    <!-- <div style="position: relative;min-height: 200px;">
      <div v-if="enableShowList" style="display:flex;position:relative;cursor: pointer;" @click="gotoDetail(lastestInfo.id)">           
        <div style="width:100%;height:50vh" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'height': '35vh'} : {}]">
          <img class="inspirationImage" style="object-fit: cover;width:100%;height:100%" :src="lastestInfo.images != undefined && lastestInfo.images.length > 0 ? lastestInfo.images[0] : ''" alt="">
        </div>
        <div style="position:absolute;top:30%;left:15%;" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'width': '80%', 'top':'15%'} : {'width':'40%'}]">
          <div style="display:flex;">
            
            <div class="inspirationTopText"  v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'font-size': '12px'} : {}]">
              {{lastestInfo.userName}}  •  {{moment(lastestInfo.createdDate).format("MMMM DD, YYYY")}}
              </div>
          </div>
          <div class="inspirationTopText2" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'font-size': '20px'} : {}]">{{lastestInfo.title}} </div>
          <div class="inspirationTopText3">{{lastestInfo.detail}}</div>
        </div>
      </div>
      <div v-else class="hotelSelectRoomTopImgViewerElseDiv">
          <img class="hotelSelectRoomTopImgViewerElseImg"
          :src="'../images/logo.gif'" alt="" width="100%" height="100%">
      </div>

    </div> -->


    <div style="margin-top:16px;margin-bottom:20px;width:100%;user-select: none;">
      <div class="inspirationItemList" id="dib-posts">
        <!-- <div style="margin-right: 10px;" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'width': '100%'} : {'width':'70%'}]">
          <a-list v-if="!loadingIns && showlist.length > 0" :grid="{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }" :pagination="pagination" :data-source="showlist">
            <a-list-item slot="renderItem" slot-scope="item">
              <div class="inspirationItemMain" @click="gotoDetail(item.id)">              
                <div style="width:100%;height:25vh">
                  <img style="object-fit: cover;width:100%;height:100%" :src="item.images != undefined && item.images.length > 0 ? item.images[0] : ''" alt="" width="100%">
                </div>
                <div style="margin:10px;padding:10px">
                  <div style="display:flex;">
                    <div class="inspirationUserText" >
                      {{item.userName}} • {{moment(item.createdDate).format("MMMM DD, YYYY")}}
                      </div>
                  </div>
                  <div class="inspirationUserDetail">{{item.title}}</div>
                  
                  <div class="inspirationItemDetail">{{item.detail}}</div>
                </div>
              </div>
            </a-list-item>
          </a-list>
          <div v-else-if="!loadingIns && showlist.length <= 0">  
            
            <div style="text-align: center;margin-top: 50px;">
              <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fillRule="evenodd"><ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse><g fillRule="nonzero" stroke="#D9D9D9"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path></g></g></svg>
            </div>
            <div class="resortEmptyDiv">
              Apologies as we do not have a property that matches your criteria.
              Please select another option or contact our Concierge via chat to assist you further.
            </div>
          </div>
          <div v-else class="homeLoadingDiv">
            <img class="homeLoadingGifDiv"
            :src="'../images/logo.gif'" alt="" width="100px" height="100px" loop=infinite>
          </div>
        </div>
        <div class="inspirationSearchPanel" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'width': '100%'} : {'width':'30%'}]">
          
          <div style="margin-top:0px;">
            <div class="inspirationSearchTitle">Categories</div>
            <div style="margin-bottom:20px">
              <div v-for="item in tagList" :key="item.key">
                <dir class="inspirationLine"></dir>
                <div style="display:flex;cursor: pointer;" @click="OnClickTagCount(item)"> 
                  <div class="inspirationTagCellTitle">{{item.name}}</div>
                  <div class="inspirationTagCellCount">({{countInfo[item.key] != undefined ? countInfo[item.key] : 0}})</div>
                </div>
              </div>
            </div>
          </div>
          <div class="instagramSocialMainParent">
            <div class="inspirationFollowTitle">KEEP IN TOUCH</div>
            <ul class="inspirationShareMain">
              <li class="ssi-email">
                <a href="mailto:hello@vuecollection.com">
                  <img class="inspirationShareIcon" src="../assets/images/icon-share-email.png">
                </a>
              </li>
              <li class="ssi-facebook">
                <a href="https://www.facebook.com/vuemaldivescollection/">
                  <img class="inspirationShareIcon" src="../assets/images/icon-share-facebook.png">
                </a>
              </li>
              <li class="ssi-instagram">
                <a href="https://www.instagram.com/vuemaldivescollection">
                  <img class="inspirationShareIcon" src="../assets/images/icon-share-instagram.png">
                </a>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </lazy-component>
</template>
<script>
import { api } from '../helpers/Helpers';
import moment from 'moment';
const data = [
  {
    name: 'Colorlib',
    date: 'MARCH 15, 2021',
    count: 3
  },
  {
    name: 'Colorlib',
    date: 'MARCH 15, 2021',
    count: 3  
  },
  {
    name: 'Colorlib',
    date: 'MARCH 15, 2021',
    count: 3  
  },
  {
    name: 'Colorlib',
    date: 'MARCH 15, 2021',
    count: 3  
  },
  {
    name: 'Colorlib',
    date: 'MARCH 15, 2021',
    count: 3  
  },
  {
    name: 'Colorlib',
    date: 'MARCH 15, 2021',
    count: 3  
  },
  {
    name: 'Colorlib',
    date: 'MARCH 15, 2021',
    count: 3  
  },
  {
    name: 'Colorlib',
    date: 'MARCH 15, 2021',
    count: 3  
  },
  {
    name: 'Colorlib',
    date: 'MARCH 15, 2021',
    count: 3  
  },
  {
    name: 'Colorlib',
    date: 'MARCH 15, 2021',
    count: 3  
  },
  {
    name: 'Colorlib',
    date: 'MARCH 15, 2021',
    count: 3  
  },
];

const searchData = [
  // {
  //   name: 'Colorlib',
  //   date: 'MARCH 15, 2021',
  //   images:[]
  // },
  // {
  //   name: 'Colorlib',
  //   date: 'MARCH 15, 2021',
  //   images:[]
  // },
];

export default {
  
  metaInfo: {
    title: 'VUE Collection',
    titleTemplate: '%s',
    meta: [
      {name: 'description', content: 'Find your travel inspiration with VUE Collection Collection. Read captivating travel stories and discover unique experiences for our luxury Collection resorts.'},

    ], 
    link: [
      {rel: 'canonical', href: 'https://vuemaldives.com/inspiration'}
    ]
  },
  data() {
    return {
      data,
      searchData,
      pagination: {
        
        // eslint-disable-next-line no-unused-vars
        onChange: page => {

        },
        pageSize: 4,
      },

      alllist:[],
      showlist:[],
      lastestInfo:{},
      countInfo:{},

      tagList : [],

      selectedKey : undefined,

      enableShowList:false,

      loadingIns:false

    };
  },

  created: function () {
    this.countFood = 0;
    this.countTravel = 0;
    this.countLifestyle = 0;
    this.countBusiness = 0;
    this.countAdventure = 0;

    this.enableShowList = false;

    // this.initData();
  },
  mounted(){
      
      let Script = document.createElement("script");
      Script.setAttribute("src", "https://io.dropinblog.com/embedjs/546391a1-2a19-4a34-b6cd-c5ae049a14e9.js");

      document.body.appendChild(Script); 
    //   document.addEventListener('DOMContentLoaded', function() {
    //     main();
    // });

    // let sss = document.getElementsByTagName('script');
    // console.log("=======================================", sss);
    // document.addEventListener('DOMContentLoaded', () => {
    //   const script = document.createElement('script');
    //   script.src = 'https://io.dropinblog.com/embedjs/546391a1-2a19-4a34-b6cd-c5ae049a14e9.js';
    //   document.body.appendChild(script);
    // });
  },
  watch:{
    $route (){
      this.updateInspirationList();
    }
  },
  methods: {
    moment,
    
    initData:async function(){
      this.loadingIns = true;
      await this.getInspirationTags();
      this.getInspirations();
    },

    getInspirationTags:async function(){
        const res = await api.getInspirationTagInfo();
        if (res != undefined && res.length > 0 && res[0].datalist != undefined){
            this.tagList = res[0].datalist;
        }
    },

    onSearch(value) {
      this.searchData = [];
      for (let item in this.alllist){
        if (this.alllist[item].title.includes(value))
          this.searchData.push(this.alllist[item]);
        if (this.searchData.length > 2)
          break;
      }
    },
    getInspirations: async function() {
      this.loadingIns = true;
        const res = await api.getInspirations();
        if (res != undefined){
          this.reMakingList(res);
          this.updateInspirationList();
        }
      this.loadingIns = false;
    },
    reMakingList(res){
        res.sort(function(a,b) {return (new Date(a['createdDate']) < new Date(b['createdDate'])) ? 1 : (new Date((b['createdDate']) < new Date(a['createdDate'])) ? -1 : 0);} );
        this.alllist = [];
        this.showlist = [];
        for(let i = 0; i < res.length; i++){
            var userName = '';
            if (res[i].userInfo != undefined)
                userName = res[i].userInfo.firstName + " " + res[i].userInfo.lastName;
            var detail = res[i].detail;                
            // if (detail.length > 100)
            //     detail = detail.substring(0, 100) + "...";
            var title = res[i].title;                
            // if (title.length > 200)
            //     title = title.substring(0, 200) + "...";
            let detailInfo = res[i].detailInfo;
            let imageTypeList = detailInfo.filter(obj => obj.type == "Image");
            let allImageList = [];
            if (imageTypeList.length > 0){
              for (let iType = 0; iType < imageTypeList.length; iType++){
                if (imageTypeList[iType] != undefined && imageTypeList[iType].images != undefined && imageTypeList[iType].images.length > 0)
                  allImageList = allImageList.concat(imageTypeList[iType].images)
              }
            }

            let textTypeList = detailInfo.filter(obj => obj.type == "Text");
            
            if (textTypeList.length > 0){
              if (textTypeList[0] != undefined && textTypeList[0].content != undefined)
                detail = textTypeList[0].content;              
            }

            const cellInfo = {
                title: title,
                detail: detail,
                userName: userName,
                tags: res[i].tags,
                images: allImageList,
                commetCount: res[i].commetCount,
                createdDate: new Date(res[i].createdDate),
                id: res[i]._id,
            };
            this.alllist.push(cellInfo);
            // this.showlist.push(cellInfo);
        }
    },

    updateInspirationList(){
      this.selectedKey = this.$route.query.key;
      this.showlist = [];
      if (this.selectedKey !== undefined){
        this.showlist = this.alllist.filter(obj => obj.tags.includes(parseInt(this.selectedKey)));
      }
      else{
        this.showlist = this.showlist.concat(this.alllist);
      }
      
      // if (this.showlist.length > 0){
      //   this.lastestInfo = this.showlist[0];
      //   this.showlist.splice(0, 1);
      // }

      this.getTagCountInfo();

      this.enableShowList = true;
    },
    getTagCountInfo(){
      for (let cell in this.tagList){
        this.countInfo[this.tagList[cell].key] = this.getCellCountInfo(this.tagList[cell].key);
      }
    },
    getCellCountInfo(_tagKey){
      var result = 0;
      for (let item in this.alllist){
        if(this.alllist[item].tags.includes(_tagKey))
          result += 1;
      }
      return result;
    },
    
    gotoDetail(id){
      this.$router.push({ path: '/inspirationsDetail/', query: {id:id}})
    },

    OnClickTagTap(_info){
      this.selectedKey = this.$route.query.key;
      if (this.selectedKey != undefined && parseInt(this.selectedKey) == _info.key){
        this.$router.push({ path: '/inspiration/', query: {}})
      }
      else{
        this.$router.push({ path: '/inspiration/', query: {key:_info.key}})
      }

    },
    OnClickTagCount(_info){
      this.$router.push({ path: '/inspiration/', query: {key:_info.key}})
    }
  },
};
</script>
<style></style>
